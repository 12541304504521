
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from './Components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Sallat from './pages/sallat';
import Asnisp from './pages/Asnisp';
import Ispasn2 from './pages/ispasn2';
import Pharmacie from './pages/pharmacie';
import Sallat3 from './pages/sallat3';
import Sallat4 from './pages/sallat4';
import Sallat5 from './pages/sallat5';
import Sallat6 from './pages/sallat6';
import Sallat7 from './pages/sallat7';

import Sallat8 from './pages/sallat8';









function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="sallat" element={<Sallat />} />
          <Route path="asn" element={<Asnisp />} />
          <Route path="asn2" element={<Ispasn2 />} />
          <Route path="pharmacie" element={<Pharmacie />} />
          <Route path="Sallat3" element={<Sallat3 />} />
          <Route path="Sallat4" element={<Sallat4 />} />
          <Route path="Sallat5" element={<Sallat5 />} />
          <Route path="Sallat6" element={<Sallat6 />} />
          <Route path="Sallat7" element={<Sallat7 />} />
          <Route path="Sallat8" element={<Sallat8 />} />




          
        </Route>
        {/* Other top-level routes */}
      </Routes>
    </Router>
  );
}

export default App;
